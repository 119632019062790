export const seo = {
  url: 'wycena',
  title: {
    pl: 'Kontenery Morskie | Wycena Kontenerów',
    en: 'Quote | International and domestic transport',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: 'Make an inquiry for a quote for international transport and domestic transport. Gain an experienced logistics partner. Work with a Polish freight forwarder.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Wycena Kontenerów',
    en: 'Transport Quote',
  },
  desc: {
    pl: 'Złóż zapytanie na wycenę kontenerów. Zyskaj Partnera Biznesowego z dużą dostępnością kontenerów oraz szybką realizację.',
    en: 'Make an inquiry for a quote for international transport and domestic transport. Gain an experienced logistics partner. Work with a Polish freight forwarder.',
  },
}

export const main = {
  title: {
    pl: 'Zyskaj Partnera Biznesowego z dużą dostępnością kontenerów oraz szybką realizacją.',
    en: 'Llooking for transport? Make a free quotation request for the transport service.',
  },
  texts: [
    {
      pl: '<span>Wybierz doświadczoną firmę transportową. Zrealizowaliśmy ponad <strong>2 mln transportów</strong> w Europie i na świecie. Określ miejsce i datę załadunku oraz rozładunku Twojego towaru. Opcjonalnie podaj parametry poszczególnych ładunków. W wiadomości możesz opisać założenia i potrzeby zlecenia. Nasi spedytorzy skontaktują się z Tobą mailowo lub telefonicznie <strong>najszybciej, jak to możliwe</strong>.</span>',
      en: '<span>Choose an experienced forwarding company. We have completed over <strong>2 million shipments</strong> in Europe and around the world. Specify the place and date of loading and unloading your goods. Optionally, enter the parameters of individual loads. In the message, you can describe the assumptions and needs of the order. Our forwarders will contact you by e-mail or phone <strong> as soon as possible </strong>.</span>',
    },
  ],
}
